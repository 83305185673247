import React from "react";
import Layout from "./Layout";
import { WaveTop, WaveBottom } from "../components/Waves";

const MainLayout = ({ title, children }) => (
    <Layout title={title}>

        <WaveTop />

        <main className="bg-white text-black">
            <div className="container max-w-5xl mx-auto p-8">
                {children}
            </div>
        </main>

        <WaveBottom />

    </Layout>
);

export default MainLayout;