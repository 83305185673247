import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import React from "react";
import MainLayout from "../layout/MainLayout";

type SectionProps = {
    id: string,
    title: string,
    children: React.ReactNode
};

const Section = ({
    id,
    title,
    children
}: SectionProps) => {
    return (
        <section id={id} className="mb-4">
            <span className="text-2xl font-semibold">{title}</span>
            <div className="whitespace-pre-line break-words">{children}</div>
        </section>
    );
};

type SectionLinkProps = {
    to: string,
    children: React.ReactNode,
    title: string
};

const SectionLink = ({
    to,
    children,
    title
}: SectionLinkProps) => {
    return (
        <li className="mx-3 hover:bg-blue-600 hover:text-blue-200">
            <Link to={to} title={title} className="hover:bg-blue-600 hover:text-blue-200">
                {children}
            </Link>
        </li>
    );
};

type AvisoLegalPageProps = {

};

const AvisoLegalPage = ({

}: AvisoLegalPageProps) => {
    const webSite = <OutboundLink href="https://www.mobotixonline.com/" title="Página de Mobotix Online" target="_blank" rel="noopener">www.mobotixonline.com</OutboundLink>;
    const companyFullName = `ENTTIA 3000 S.L.`;
    const companyName = `ENTTIA`;

    return (
        <MainLayout title="Términos y condiciones">
            <div className="flex flex-column md:flex-row">

                <div className="hidden md:inline w-1/3 sticky px-6 ">

                    <p className="font-semibold">Términos y condiciones</p>
                    <ul className="divide-y-2 divide-gray-100 pb-6">
                        <SectionLink to="#aviso-legal" title="Ir a la sección «Aviso legal»">
                            AVISO LEGAL
                        </SectionLink>
                        <SectionLink to="#finalidad" title="Ir a la sección «Finalidad de la página web»">
                            FINALIDAD DE LA PÁGINA WEB
                        </SectionLink>
                        <SectionLink to="#usuarios" title="Ir a la sección «Usuario/s»">
                            USUARIO/S
                        </SectionLink>
                        <SectionLink to="#legislacion" title="Ir a la sección «Legislación»">
                            LEGISLACIÓN
                        </SectionLink>
                        <SectionLink to="#uso-y-acceso-de-usuarios" title="Ir a la sección «Uso y acceso de usuarios»">
                            USO Y ACCESO DE USUARIOS
                        </SectionLink>
                        <SectionLink to="#propiedad-intelecual-e-industrial" title="Ir a la sección «Propiedad intelectual e industrial»">
                            PROPIEDAD INTELECTUAL E INDUSTRIAL
                        </SectionLink>
                        <SectionLink to="#contenido-de-la-web-y-enlaces" title="Ir a la sección «Contenido de la web y enlaces»">
                            CONTENIDO DE LA WEB Y ENLACES (LINKS)
                        </SectionLink>
                        <SectionLink to="#exclusion-de-garantias-y-responsabilidad" title="Ir a la sección «Exclusión de garantías y responsabilidad»">
                            EXCLUSIÓN DE GARANTÍAS Y RESPONSABILIDAD
                        </SectionLink>
                        <SectionLink to="#competencia-judicial" title="Ir a la sección «Competencia judicial»">
                            COMPETENCIA JUDICIAL
                        </SectionLink>
                    </ul>

                    <p className="font-semibold">Política de cookies</p>
                    <ul className="divide-y-2 divide-gray-100 pb-6">
                        <SectionLink to="#politica-de-cookies" title="Ir a la sección «Política de cookies»">
                            POLÍTICA DE COOKIES
                        </SectionLink>
                        <SectionLink to="#que-es-una-cookie" title="Ir a la sección «¿Qué es una cookie?»">
                            ¿QUÉ ES UNA COOKIE?
                        </SectionLink>
                        <SectionLink to="#que-tipo-de-cookies-usamos" title="Ir a la sección «¿Qué tipo de cookies usamos?»">
                            ¿QUÉ TIPO DE COOKIES USAMOS?
                        </SectionLink>
                        <SectionLink to="#que-cookies-usamos-y-para-que-las-usamos" title="Ir a la sección «¿Qué cookies usamos y para qué las usamos?»">
                            ¿QUÉ COOKIES USAMOS Y PARA QUÉ LAS USAMOS?
                        </SectionLink>
                        <SectionLink to="#como-puedo-gestionar-mis-cookies-y-como-puedo-deshabilitarlas" title="Ir a la sección «¿Cómo puedo gestionar mis cookies y cómo puedo deshabilitarlas?»">
                            ¿CÓMO PUEDO GESTIONAR MIS COOKIES Y CÓMO PUEDO DESHABILITARLAS??
                        </SectionLink>
                    </ul>
                </div>

                <div className="md:w-2/3">

                    <Section id="aviso-legal" title="AVISO LEGAL">
                        <p>En cumplimiento del artículo 10 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y Comercio Electrónico (LSSICE) a continuación se detallan los datos identificativos de la empresa:</p>
                        <ul className="pl-12 list-disc">
                            <li><strong>Nombre de la empresa</strong>: {companyName}</li>
                            <li><strong>Razón social</strong>: {companyFullName}</li>
                            <li><strong>NIF</strong>: B62709464</li>
                            <li><strong>Direcciones</strong>: Ronda de Ponent, 2</li>
                            <li><strong>Teléfono</strong>: 93 745 07 00</li>
                            <li><strong>Email</strong>: info@enttia.es</li>
                            <li><strong>Sitio Web</strong>: {webSite}</li>
                        </ul>
                    </Section>

                    <Section id="finalidad" title="FINALIDAD DE LA PÁGINA WEB">
                        <p>Los servicios prestados por {companyName} son los relativos a planificación e instalación de sistemas.</p>
                        <p>El presente aviso legal (en adelante, el “Aviso Legal”) regula el uso del sitio web: {webSite}</p>
                    </Section>

                    <Section id="usuarios" title="USUARIO/S">
                        <p>El acceso y/o uso de este sitio web atribuye a quien lo realiza la condición de Usuario, aceptando, desde este mismo momento, plenamente y sin reserva alguna, el presente Aviso Legal, así como las condiciones particulares que, en su caso, lo complementen, en relación con determinados servicios y contenidos del sitio Web.</p>
                    </Section>

                    <Section id="legislacion" title="LEGISLACIÓN">
                        <p>Con carácter general las relaciones entre {companyFullName} (En adelante {companyName} el prestador) con los Usuarios de sus servicios telemáticos, presentes en este sitio web, se encuentran sometidas a la legislación y jurisdicción españolas.</p>
                    </Section>

                    <Section id="uso-y-acceso-de-usuarios" title="USO Y ACCESO DE USUARIOS">
                        <p>El Usuario queda informado, y acepta, que el acceso a la presente web no supone, en modo alguno, el inicio de una relación comercial con {companyFullName} ({companyName}).</p>
                        <p>De esta forma, el usuario se compromete a utilizar el sitio Web, sus servicios y contenidos sin contravenir la legislación vigente, la buena fe y el orden público. Queda prohibido el uso de la web con fines ilícitos o lesivos, o que, de cualquier forma, puedan causar perjuicio o impedir el normal funcionamiento del sitio web. Respecto de los contenidos de este site, se prohíbe:</p>
                        <ul className="pl-6 list-disc">
                            <li>Su reproducción, distribución o modificación, total o parcial, a menos que se cuente con la autorización de sus legítimos titulares.</li>
                            <li>Cualquier vulneración de los derechos del prestador o de los legítimos titulares.</li>
                            <li>Su utilización para fines comerciales o publicitarios.</li>
                        </ul>
                    </Section>

                    <Section id="propiedad-intelecual-e-industrial" title="PROPIEDAD INTELECTUAL E INDUSTRIAL">
                        <p>Los derechos de propiedad intelectual del contenido de las páginas web, su diseño gráfico y códigos son titularidad de {companyFullName} ({companyName}) y, por tanto, queda prohibida su reproducción, distribución, comunicación pública, transformación o cualquier otra actividad que se pueda realizar con los contenidos de sus páginas web ni aún citando las fuentes, salvo consentimiento por escrito de {companyFullName} ({companyName}).</p>
                    </Section>

                    <Section id="contenido-de-la-web-y-enlaces" title="CONTENIDO DE LA WEB Y ENLACES (LINKS)">
                        <p>{companyFullName} ({companyName}) se reserva el derecho a actualizar, modificar o eliminar la información contenida en sus páginas web pudiendo incluso limitar o no permitir el acceso a dicha información a ciertos usuarios.</p>
                        <p>El Prestador no asume responsabilidad alguna por la información contenida en páginas web de terceros a las que se pueda acceder por “links” o enlaces desde cualquier página web propiedad de {companyFullName} La presencia de “links” o enlaces en las páginas web de {companyFullName} tiene finalidad meramente informativa y en ningún caso supone sugerencia, invitación o recomendación sobre los mismos.</p>
                    </Section>

                    <Section id="exclusion-de-garantias-y-responsabilidad" title="EXCLUSIÓN DE GARANTÍAS Y RESPONSABILIDAD">
                        <p>El Prestador no otorga ninguna garantía ni se hace responsable, en ningún caso, de los daños y perjuicios de cualquier naturaleza que pudieran traer causa de:</p>

                        <ul className="pl-6 list-disc">
                            <li>La falta de disponibilidad, mantenimiento y efectivo funcionamiento de la web o de sus servicios y contenidos.</li>
                            <li>La existencia de virus, programas maliciosos o lesivos en los contenidos.</li>
                            <li>El uso ilícito, negligente, fraudulento o contrario a este Aviso Legal.</li>
                            <li>La falta de licitud, calidad, fiabilidad, utilidad y disponibilidad de los servicios prestados por terceros y puestos a disposición de los usuarios en el sitio web</li>
                        </ul>

                        <p>El prestador no se hace responsable bajo ningún concepto de los daños que pudieran dimanar del uso ilegal o indebido de la presente página web.</p>
                    </Section>

                    <Section id="competencia-judicial" title="COMPETENCIA JUDICIAL">
                        <p>Las partes se someten, a su elección, para la resolución de conflictos y con renuncia a cualquier otro fuero, a los juzgados y tribunales del domicilio del prestador.</p>
                    </Section>

                    <hr className="my-6" />

                    <Section id="politica-de-cookies" title="0. Política de Cookies" >
                        <p>En {webSite} utilizamos cookies con el objetivo de prestar un mejor servicio y proporcionarte una mejor experiencia en tu navegación. En ningún caso las cookie serán archivos ejecutables no pudiendo contener virus y su única finalidad será la de brindarte una mejor experiencia en nuestro sitio web.</p>
                        <p>Queremos informarte de manera clara y precisa sobre las cookies que utilizamos, detallando a continuación, que es una cookie, para que sirve, que tipos de cookies utilizamos, cuales son su finalidad y cómo puedes configurarlas o deshabilitarlas si así lo deseas.</p>
                    </Section>

                    <Section id="que-es-una-cookie" title="1. ¿Qué es una cookie?">
                        <p>Una “Cookie” es un pequeño archivo que se almacena en el ordenador del usuario, tablet, smartphone o cualquier otro dispositivo con información sobre la navegación de los usuarios en la web de {webSite}</p>
                        <p>El conjunto de “cookies” de todos nuestros usuarios nos ayuda a mejorar la calidad de nuestra web, permitiéndonos controlar qué páginas son útiles, cuáles no y cuáles son susceptibles de mejora. Las cookies son esenciales para el funcionamiento de internet, aportando innumerables ventajas en la prestación de servicios interactivos, facilitándote la navegación y usabilidad de nuestra web. En ningún caso las cookies podrían dañar tu equipo. Por contra, el que estén activas nos ayuda a identificar y resolver los errores.</p>
                    </Section>

                    <Section id="que-tipos-de-cookies-usamos" title="2. ¿Qué tipos de cookies usamos?">
                        <span className="font-semibold">Tipos de cookies según la entidad que las gestione</span>
                        <ul className="pl-6 list-disc">
                            <li>Cookies propias: Son aquéllas que se envían al equipo terminal del usuario desde un equipo o dominio gestionado por el propio editor y desde el que se presta el servicio solicitado por el usuario.</li>
                            <li>Cookies de tercero: Son aquéllas que se envían al equipo terminal del usuario desde un equipo o dominio que no es gestionado por el editor, sino por otra entidad que trata los datos obtenidos través de las cookies.</li>
                        </ul>

                        <br />

                        <span className="font-semibold">Tipos de cookies según el plazo de tiempo que permanecen activadas</span>
                        <ul className="pl-6 list-disc">
                            <li>Cookies de sesión: Son un tipo de cookies diseñadas para recabar y almacenar datos mientras el usuario accede a una página web. Se suelen emplear para almacenar información que solo interesa conservar para la prestación del servicio solicitado por el usuario en una sola ocasión (p.e. una lista de productos adquiridos).</li>
                            <li>Cookies persistentes: Son un tipo de cookies en el que los datos siguen almacenados en el terminal y pueden ser accedidos y tratados durante un periodo definido por el responsable de la cookie, y que puede ir de unos minutos a varios años.</li>
                        </ul>

                        <br />

                        <p>Tipos de cookies según su finalidad Según la finalidad para la que se traten los datos obtenidos a través de las cookies, podemos distinguir entre:</p>
                        <ul className="pl-6 list-disc">
                            <li>Cookies de personalización: Son aquéllas que permiten al usuario acceder al servicio con algunas características de carácter general predefinidas en función de una serie de criterios en el terminal del usuario como por ejemplo serian el idioma, el tipo de navegador a través del cual accede al servicio, la configuración regional desde donde accede al servicio, etc.</li>
                            <li>Cookies de análisis: Son aquéllas que permiten al responsable de las mismas, el seguimiento y análisis del comportamiento de los usuarios de los sitios web a los que están vinculadas. La información recogida mediante este tipo de cookies se utiliza en la medición de la actividad de los sitios web, aplicación o plataforma y para la elaboración de perfiles de navegación de los usuarios de dichos sitios, aplicaciones y plataformas, con el fin de introducir mejoras en función del análisis de los datos de uso que hacen los usuarios del servicio.</li>
                        </ul>
                    </Section>

                    <Section id="que-cookies-usamos-y-para-que-las-usamos" title="3. ¿Qué cookies usamos y para qué los usamos?">
                        <span className="font-semibold">Cookies propias:</span>
                        <ul className="pl-6 list-disc pb-3">
                            <li>Cookies: Estrictamente necesarias</li>
                            <li>Información: Técnicas y de personalización (información de sesión, registro, preferencias de idioma…)</li>
                            <li>Finalidad: Cookies estrictamente necesarias para mejorar la navegación del usuario en la página y mejorar su experiencia en la web.</li>
                            <li>Más información: https://automattic.com/privacy/</li>
                        </ul>

                        <span className="font-semibold">Cookies de terceros:</span>
                        <table className="table-auto items-center bg-transparent w-full border-collapse">

                            <thead>
                                <tr>
                                    <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">Nombre del proveedor</th>
                                    <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">Información</th>
                                    <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">Finalidad</th>
                                    <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">Más información</th>
                                </tr>
                            </thead>

                            <tbody>

                                <tr>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4 text-left text-blueGray-700">
                                        Google Analytics
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4 text-left text-blueGray-700">
                                        Analítica
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4 text-left text-blueGray-700">
                                        Usamos cookies de Analytics para saber de qué forma interactúan los usuarios con nuestra página y poder optimizar la navegación en ella. Tienen una función meramente estadística y analítica.
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4 text-left text-blueGray-700">
                                        <OutboundLink href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage" title="Más información sobre Google Analytics" target="_blank" rel="noopener">
                                            Enlace
                                        </OutboundLink>
                                    </td>
                                </tr>

                                <tr>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4 text-left text-blueGray-700">
                                        Google Tag Manager
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4 text-left text-blueGray-700">
                                        Analítica
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4 text-left text-blueGray-700">
                                        Usamos estas cookies únicamente para poder gestionar de una forma correcta todos los códigos de otros proveedores en la página web.
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4 text-left text-blueGray-700">
                                        <OutboundLink href="https://policies.google.com/technologies/cookies?hl=es" title="Más información sobre Google Tag Manager" target="_blank" rel="noopener">
                                            Enlace
                                        </OutboundLink>
                                    </td>
                                </tr>

                            </tbody>

                        </table>

                    </Section>

                    <Section id="como-puedo-gestionar-mis-cookies-y-como-puedo-deshabilitarlas" title="4. ¿Cómo puedo gestionar mis cookies y cómo puedo deshabilitarlas?">
                        <p>Puedes permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la configuración de las opciones de tu navegador de Internet. En los siguientes enlaces tienes a tu disposición toda la información para configurar o deshabilitar tus cookies en cada navegador:</p>
                        <ul className="pl-6 list-disc">

                            <li>
                                <OutboundLink href="https://support.google.com/chrome/answer/95647?hl=es" title="Ver cómo gestionar las cookies usando Google Chrome" target="_blank" rel="noopener">
                                    Google Chrome
                                </OutboundLink>
                            </li>

                            <li>
                                <OutboundLink href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we" title="Ver cómo gestionar las cookies usando Mozilla Firefox " target="_blank" rel="noopener">
                                    Mozilla Firefox
                                </OutboundLink>
                            </li>

                            <li>
                                <OutboundLink href="http://windows.microsoft.com/es-es/windows-vista/block-or-allow-cookies" title="Ver cómo gestionar las cookies uando Internet Explorer" target="_blank" rel="noopener">
                                    Internet Explorer
                                </OutboundLink>
                            </li>

                            <li>
                                <OutboundLink href="http://support.apple.com/kb/ph5042?viewlocale=es_es" title="Ver cómo gestionar las cookies usando Safari" target="_blank" rel="noopener">
                                    Safari
                                </OutboundLink>
                            </li>

                            <li>
                                <OutboundLink href="http://support.apple.com/kb/ht1677?viewlocale=es_es" title="Ver cómo gestionar las cookies usando Safari para iOS" target="_blank" rel="noopener">
                                    Safari para IOS (iPhone y iPad)
                                </OutboundLink>
                            </li>

                            <li>
                                <OutboundLink href="https://support.google.com/chrome/answer/2392971?hl=es" title="Ver cómo gestionar las cookies usando Chrome para Android" target="_blank" rel="noopener">
                                    Chrome para Android
                                </OutboundLink>
                            </li>

                        </ul>

                        <p>Si quieres optar por darte de baja de las cookies de análisis de Google Analytics puedes acceder a este enlace dónde podrás hacerlo: <OutboundLink href="https://tools.google.com/dlpage/gaoptout" title="Ir a la página de Google para dar de baja las cookies de rastreo" target="_blank" rel="noopener">https://tools.google.com/dlpage/gaoptout</OutboundLink></p>
                        <p>En caso de que el usuario no permita la instalación de las cookies estrictamente necesarias en su equipo terminal a través de su navegador (las identificadas en el punto primero del apartado anterior), es posible que no pueda acceder correctamente a los contenidos y servicios de nuestra web.</p>
                        <p>No obstante, si el usuario continúa navegando en nuestra web sin cambiar la configuración de su navegador, se entenderá que presta su consentimiento al uso de las cookies antes enunciadas, y en las condiciones contenidas en la presente Política de Cookies.</p>
                    </Section>

                </div>

            </div>
        </MainLayout>
    );
};

export default AvisoLegalPage;